<script setup>
import {ref} from 'vue'

const props = defineProps({
  meter: String,
  high: String,
  low: String,
})

const meter = ref(props.meter)
const high = ref(props.high)
const low = ref(props.low)

</script>
<style>


label svg {
  height: 35px;
  width: 35px;
}

@media (min-width: 900px) {
  label svg {
    height: 60px;
    width: 40px;
  }
}

label.odd svg {
  color: white;
}

label.straight svg {
  color: white;
}

label.active svg {
}

</style>
<template>


  <div class="pt-4">
    <div class="form-check form-check-inline ps-md-3">
      <input
          class="form-check-input"
          type="radio"
          name="two_tariff_meter"
          id="meter_ht"
          value=0
          v-model="meter"
      >
      <label
          class="form-check-label ps-md-2"
          for="meter_ht"
      >
        Eintarifzähler
      </label>
    </div>
    <div class="form-check form-check-inline">
      <input
          class="form-check-input"
          type="radio"
          name="two_tariff_meter"
          id="meter_nt"
          value=1
          v-model="meter"
      >
      <label
          class="form-check-label ps-md-2"
          for="meter_nt"
      >
        Zweitarifzähler
      </label>
    </div>
  </div>

  <div
      class="row pt-4"
      v-if="meter === '0'"
  >
    <label
        for="usage_ht"
        class="col-12"
    >
      Jahresverbrauch (kWh) *:
    </label>
    <input
        id="usage_ht"
        name="usage_ht"
        class="form-control form-control-lg"
        v-model="high"
        required
    >
  </div>

  <div
      v-if="meter === '1'"
      class="row pt-4"
  >
    <div class="col-md-6 pe-0 ps-0 pe-md-2">
      <label
          for="high"
          class="col-12"
      >
        Jahresverbrauch Hoch-/Haupttarif (kWh) *:
      </label>
      <input
          id="high"
          name="usage_ht"
          class="form-control form-control-lg"
          v-model="high"
          required
      >
    </div>
    <div class="col-md-6 ps-0 pe-0 ps-md-2 pt-4 p-md-0">
      <label
          for="high"
          class="col-12"
      >
        Jahresverbrauch Nieder-/Nebentarif (kWh):
      </label>
      <input
          id="low"
          name="usage_nt"
          class="form-control form-control-lg"
          v-model="low"
      >
    </div>
  </div>
</template>