<script setup>
import {ref} from 'vue'

import PersonStanding from "./Elements/PersonStanding.vue";
import PersonArmsUp from "./Elements/PersonArmsUp.vue";

const props = defineProps({
  usage: String,
  group: String
})

const picked = ref(props.usage)
const group = ref(props.group)

const usageOnePeople = ref(1600)
const usageTwoPeople = ref(2000)
const usageThreePeople = ref(3500)
const usageFourPeople = ref(4500)
const usageFivePeople = ref(5500)

</script>
<style>


label svg {
  height: 35px;
  width: 35px;
}

#two-people-label, #three-people-label, #four-people-label, #five-people-label {
  padding-left: 1rem;
}

@media (min-width: 900px) {
  label svg {
    height: 60px;
    width: 40px;
  }

  #two-people-label, #three-people-label, #four-people-label, #five-people-label {
    padding-left: 2rem;
  }

}

label.odd svg {
  color: white;
}

label.straight svg {
  color: white;
}

label.active svg {
}

</style>
<template>

  <div class="pt-4">
    <div class="form-check form-check-inline ps-md-3">
      <input
          class="form-check-input"
          type="radio"
          name="customer_group"
          id="customer-group-hh"
          value="HH"
          v-model="group"
          checked
      >
      <label
          class="form-check-label ps-md-2"
          for="customer-group-hh"
      >
        Privatkunde
      </label>
    </div>
    <div class="form-check form-check-inline">
      <input
          class="form-check-input"
          type="radio"
          name="customer_group"
          id="customer-group-gw"
          value="GW"
          v-model="group"
      >
      <label
          class="form-check-label ps-md-2"
          for="customer-group-gw"
      >
        Geschäftskunde
      </label>
    </div>
  </div>

  <div class="row col-12 pt-4">

    <label
        for="usage"
        class="col-12"
    >
      Jahresverbrauch (kWh) *
    </label>
    <input
        id="usage"
        name="usage"
        class="form-control form-control-lg"
        v-model="picked"
        required
    >
  </div>

  <div
      class="row col-12"
      v-if="group==='HH'"
  >


    <label
        for="usage-over-people"
        class="pt-5"
    >
      Oder schätzen Sie Ihren Jahresverbrauch über die Personen in Ihrem Haushalt:
    </label>
    <div
        class="col-12 pt-5 text-center"
        id="usage-over-people"
    >
      <label
          for="one-people"
          class="odd"
          :class="{ active: picked == usageOnePeople }"
          :title="'Eine Person: ' + usageOnePeople + ' kWh'"
      >
        <div v-if="picked == usageOnePeople">
          <PersonArmsUp/>
        </div>
        <div v-else>
          <PersonStanding/>
        </div>
        <input
            type="radio"
            id="one-people"
            :value="usageOnePeople"
            v-model="picked"
            class="d-none"
        />
      </label>


      <label
          id="two-people-label"
          for="two-people"
          class="straight"
          :class="{ active: picked == usageTwoPeople }"
          :title="'Zwei Personen: ' + usageTwoPeople + ' kWh'"
      >
        <div v-if="picked == usageTwoPeople">
          <PersonArmsUp/>
          <PersonArmsUp/>
        </div>
        <div v-else>
          <PersonStanding/>
          <PersonStanding/>
        </div>
        <input
            type="radio"
            id="two-people"
            :value="usageTwoPeople"
            v-model="picked"
            class="d-none"
        />
      </label>


      <label
          id="three-people-label"
          for="three-people"
          class="odd"
          :class="{ active: picked == usageThreePeople }"
          :title="'Zwei Personen: ' + usageThreePeople + ' kWh'"
      >
        <div v-if="picked == usageThreePeople">
          <PersonArmsUp/>
          <PersonArmsUp/>
          <PersonArmsUp/>
        </div>
        <div v-else>
          <PersonStanding/>
          <PersonStanding/>
          <PersonStanding/>
        </div>
        <input
            type="radio"
            id="three-people"
            :value="usageThreePeople"
            v-model="picked"
            class="d-none"
        />
      </label>


      <label
          id="four-people-label"
          for="four-people"
          class="straight"
          :class="{ active: picked == usageFourPeople }"
          :title="'Vier Personen: ' + usageFourPeople + ' kWh'"
      >
        <div
            v-if="picked == usageFourPeople"
            class="pt-3 pt-md-0"
        >
          <PersonArmsUp/>
          <PersonArmsUp/>
          <PersonArmsUp/>
          <PersonArmsUp/>
        </div>
        <div
            v-else
            class="pt-3 pt-md-0"
        >
          <PersonStanding/>
          <PersonStanding/>
          <PersonStanding/>
          <PersonStanding/>
        </div>
        <input
            type="radio"
            id="four-people"
            :value="usageFourPeople"
            v-model="picked"
            class="d-none"
        />
      </label>


      <label
          id="five-people-label"
          for="five-people"
          class="odd"
          :class="{ active: picked == usageFivePeople }"
          :title="'Fünf Personen: ' + usageFivePeople + ' kWh'"
      >
        <div
            v-if="picked == usageFivePeople"
            class="pt-3 pt-md-0"
        >
          <PersonArmsUp/>
          <PersonArmsUp/>
          <PersonArmsUp/>
          <PersonArmsUp/>
          <PersonArmsUp/>
        </div>
        <div
            v-else
            class="pt-3 pt-md-0"
        >
          <PersonStanding/>
          <PersonStanding/>
          <PersonStanding/>
          <PersonStanding/>
          <PersonStanding/>
        </div>
        <input
            type="radio"
            id="five-people"
            :value="usageFivePeople"
            v-model="picked"
            class="d-none"
        />
      </label>

    </div>

  </div>
</template>